import React from "react"

import {
  Title,
  Subtitle,
  Section,
  Container,
  Columns,
  Column,
  Button,
} from "bloomer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFound from "../assets/not-found.svg"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <Container>
        <Columns>
          <Column isSize="1/2">
            <NotFound width="80%" height="vh" />
          </Column>
          <Column isSize="1/2">
            <Title>Not Found</Title>
            <Subtitle>
              Oops, the page you requested cannot be found.
            </Subtitle>
            <Link to="/">
              <Button isSize="large" isColor="primary">
                Home
              </Button>
            </Link>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
